var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loader-layer", class: _vm.show ? "active" : "" },
    [
      _c(
        "div",
        { staticClass: "spinner" },
        [_c("a-spin", { attrs: { spinning: _vm.show } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }